<template>
  <div class="w-full">
    <CCarousel
      :gap="20"
      :items-to-show="1.4"
      :breakpoints="breakpoints"
      class="w-full"
    >
      <CSlide
        v-for="(item, index) in loanStepsData"
        :key="index"
        class="w-full pr-4 lg:last:pr-0"
      >
        <CardLoanSlider
          :loan-steps-data="item"
          :index="index"
        />
      </CSlide>
    </CCarousel>
  </div>
</template>

<script setup>
const loanStepsData = [
  {
    title: 'Оформите заявку',
    content: 'Удобным способом на сайте или по телефону',
    img: 'Telephone0.png',
    deskImg: 'TelephoneDesk0.png',
    time: '5 минут',
  },
  {
    title: 'Дождитесь решения',
    content: 'Вы получите SMS-уведомление',
    img: 'Telephone1.png',
    deskImg: 'TelephoneDesk1.png',
    time: '10 минут',
  },
  {
    title: 'Получите деньги',
    content: 'После подписания договора',
    img: 'Telephone2.png',
    deskImg: 'TelephoneDesk2.png',
    time: 'Моментально',
  },
];

const breakpoints = {
  320: {
    itemsToShow: 1.25,
    itemsToScroll: 1,
  },
  768: {
    itemsToShow: 2.75,
    itemsToScroll: 1,
  },
  1024: {
    itemsToShow: 3,
    mouseDrag: false,
    touchDrag: false,
  },
};
</script>
